html,
body {
    overscroll-behavior-y: contain;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
